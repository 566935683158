import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <StyledFooter>
      Copyright © {currentYear}, South African Regional Paintball League, All Rights Reserved
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background-color: #222;
  padding: 0.6em;
  font-size: 0.8rem;
  text-align: center;
`

export default Footer;
