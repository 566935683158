import React from 'react'
import styled from 'styled-components' // , { createGlobalStyle }
import Navbar from './Navbar'
import Footer from './Footer'

// import "../../styles/typography.css";

const Layout = ({ children, path, location }) => {
  return (
    <>
      {/* <GlobalStyle /> */}
      <LayoutStyles>
        <Navbar path={path} location={location} />
        {children}
        <Footer />
      </LayoutStyles>
    </>
  )
}

const LayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
`

// const GlobalStyle = createGlobalStyle`
//   :root {
//     --primary-color: #800080;
//     --secondary-color: #0a5b70;
//     --site-bg-color: #111;
//     --bg-dark: #111;
//     --primary-text-color: #f4f4f4;
//   }

//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }

//   html {
//     scroll-behavior: smooth;
//   }

//   body {
//     background-color: var(--site-bg-color);
//     color: var(--primary-text-color);
//     font-family: Arial, Helvetica, sans-serif;
//   }

//   a {
//     color: inherit;
//   }

//   .site-container {
//     max-width: 1400px;
//     margin: auto;
//   }

//   .ta-center {
//     text-align: center;
//   }

//   a.event-card-btn {
//     display: inline-block;
//     /* margin-top: auto; */
//     padding: 0.4em 0.8em;
//     width: fit-content;
//     border: solid 4px var(--primary-color);
//     border-radius: 50px;
//     font-size: 0.85rem;
//     letter-spacing: 1px;
//     text-transform: uppercase;
//     text-decoration: none;
//     transition: background-color 0.2s;

//     &:hover {
//       background-color: var(--primary-color);
//     }
//   }

//   .visually-hidden {
//     clip: rect(1px, 1px, 1px, 1px);
//     height: 1px;
//     width: 1px;
//     overflow: hidden;
//     white-space: nowrap;
//     position: absolute;
//   }
// `

export default Layout
