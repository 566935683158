exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-sanity-event-id-js": () => import("./../../../src/pages/events/{sanityEvent._id}.js" /* webpackChunkName: "component---src-pages-events-sanity-event-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

