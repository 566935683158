import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import sarplLogo from '../../images/sarpl-logo.png'
import sarplLogoWhite from '../../images/sarpl-logo-white.png'

const Navbar = ({ path, location }) => {
  const navbarRef = useRef(null)

  // closes mobile menu when navigating to diffirent page
  useEffect(() => {
    if (!navbarRef.current.location) {
      navbarRef.current.location = location
    } else if (navbarRef.current.location !== location) {
      navbarRef.current.checked = false
      navbarRef.current.location = location
    }
  }, [location])
  
  return (
    <StyledNavbar>
      <div className="site-container">
        {/* <div className="nav-line"></div> */}
        <Link to="/" id='logo'>
          <img src={path === '/' ? sarplLogoWhite : sarplLogo} alt="SARPL logo" />
        </Link>
        {/* <!-- Hamburger --> */}
        <input type="checkbox" className="toggler" ref={navbarRef} />
        <div className="hamburger">
          <div className="ham-line1"></div>
          <div className="ham-line2"></div>
          <div className="ham-line3"></div>
        </div>
        {/* <!-- List --> */}
        <ul>
          {/* <li><Link to="/intro" activeClassName='active'>Getting Started</Link></li> */}
          <li><Link to="/#season" activeClassName='active'>Season 2022</Link></li>
          <li><Link to="/register/" activeClassName='active'>Register</Link></li>
          <li><Link to="/about/" activeClassName='active'>About Us</Link></li>
          <li><Link to="/contact/" activeClassName='active'>Contact</Link></li>
        </ul>
      </div>
    </StyledNavbar>
  )
}

const StyledNavbar = styled.nav`
  background-color: #1a1a1a;
  height: 3.75rem;
  /* padding: 0 2rem; */
  position: sticky;
  top: 0;
  z-index: 10;

  .site-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  a#logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5rem;
    height: 100%;
    position: relative;
    z-index: 2;

    img {
      object-fit: contain;
      height: 75%;
    }

    &:before {
      content: '';
      background-color: var(--primary-color);
      height: 100%;
      width: 3rem;
      transform: skewX(-10deg);
      position: absolute;
      left: 0;
      z-index: -1;
    }
  }
  
  input[type="checkbox"] {
    display: none;
    position: absolute;
    right: 0;
    height: 100%;
    width: 3.75rem;
    z-index: 11;
    opacity: 0;

    &:checked ~ .hamburger {
      & > *:nth-child(1) { transform: rotate(-45deg); }
      & > *:nth-child(2) { opacity: 0; }
      & > *:nth-child(3) { transform: rotate(45deg); }
    }
  }

  div.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    aspect-ratio: 1/1;
    width: 3.75rem;
    height: 100%;
    padding: 7.5px;
    position: absolute;
    right: 0;

    & > * {
      background-color: #fafafa;
      width: 75%;
      height: 3px;
      transition: transform 0.5s, opacity 0.5s;
      transform-origin: right;
    }

    /* div.ham-line1 {}
    div.ham-line2 {}
    div.ham-line3 {} */
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    background-color: #1a1a1a;
    list-style: none;
    z-index: 10;

    li {
      flex: 0 0 auto;

      a {
        display: inline-block;
        padding: 0.6em 1.4em;
        text-decoration: none;
        /* border-bottom: solid 2px transparent; */
        position: relative;
        width: fit-content;

        &:after,
        &.active:after {
          content: '';
          position: absolute;
          left: calc(0em + 1.4em);
          bottom: calc(0em + 0.4em);
          width: calc(100% - 2.8em);
          height: 3px;
          background-color: var(--primary-color);
          transform-origin: left;
          transform: scale(0) skewX(-25deg);
          transition: transform 0.5s, background-color 0.5s;
        }

        &:hover:after {
          transform: scale(1) skewX(-25deg);
        }

        &.active:after {
          background-color: var(--secondary-color);
          transform: scale(1) skewX(-25deg);
        }
        
        &.active:hover:after {
          background-color: var(--primary-color);
        }
      }
    }
  }

  @media (max-width: 480px) {
    /* padding: 0 1rem; */

    input[type="checkbox"] {
      display: block;

      &:checked ~ ul {
        transform: translateX(0);
      }
    }

    div.hamburger {
      display: flex;
    }

    ul {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 80vw;
      flex-flow: column;
      margin-top: 3.75rem;
      font-size: 1.2rem;
      transform: translateX(100%);
      transition: transform 0.4s;

      li {
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        
        &:first-child {
          border-top: solid 1px rgba(255, 255, 255, 0.1);
        }

        a {
          width: 100%;

          &:after,
          &.active:after {
            top: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 5px;
            transform: none;
          }

          &:hover:after {
            transform: none;
          }

          &.active:after {
            transform: none;
          }

          &.active:hover:after {
            background-color: var(--secondary-color);
          }
        }
      }
    }
  }
`

export default Navbar
